.Home {
    min-height: 100svh;
    width: 100svw;
    background-color: black;
}
.Hiro {
    width: 100%;
    height: 100svh;
    background-image: url("../../../assets/home-background.svg");
    background-size: cover;
    background-position: center;
    display: grid;
    place-items: center;
}
.HiroContent {
    width: 60%;
    height: 50%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 1fr;
    grid-template-areas:
        "header"
        "subheader"
        "buttons";
}
.HiroContent .header {
    grid-area: header;
    font-size: 2rem;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.HiroContent .subheader {
    grid-area: subheader;
    font-size: 1.2rem;
    color: var(--small-text-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.HiroContent .buttons {
    grid-area: buttons;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--text-color);
    font-size: 1.5rem;
    cursor: pointer;
    background-color: var(--accent-color);
    text-decoration: none;
}
.button:hover {
    filter: brightness(0.9);
}
.button-alt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--text-color);
    font-size: 1.5rem;
    cursor: pointer;
    background-color: var(--background-color);
    text-decoration: none;
    border: 1px solid var(--accent-color);
}
.button-alt:hover {
    filter: brightness(0.9);
}

@media screen and (max-width: 770px) {
    .HiroContent {
        grid-template-rows: 1fr 1fr 2fr;
    }
    .HiroContent .buttons {
        flex-direction: column;
        align-items: center;
    }
}