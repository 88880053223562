.Dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    height: fit-content;
    background-color: var(--background-color);
}
.Dashboard main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    padding: 1rem;
}
.dbHeader {
    font-size: 3rem;
    margin: 0;
}
.welcome {
    font-size: 1.1rem;
    margin: 0;
    color: var(--small-text-color);
}
.welcome span {
    color: var(--accent-color);
}