.Login, .Logout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-color);
}
.header {
    font-size: 2rem;
    color: var(--text-color);
    margin-bottom: 1rem;
}
.form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50vw;
    height: fit-content;
    max-width: 500px;
    border-radius: 20px;
}
.form label {
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}
.form input {
    width: 90%;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    margin-bottom: 1rem;
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding-left: 0.5rem;
}
.form input:focus {
    outline: none;
    border: 1px solid var(--accent-color);
}
.form button {
    width: 40%;
    height: 3rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    border: none;
    background-color: var(--accent-color);
    color: var(--text-color);
    font-size: 1.2rem;
    cursor: pointer;
}
.form button:hover {
    filter: brightness(0.9);
}
.error {
    color: var(--warning-color);
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
}