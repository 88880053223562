.Profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    height: fit-content;
    background-color: var(--background-color);
    position: relative;
}
.Profile main {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 2fr 3fr;
    grid-template-rows: 2fr 3fr;
    place-items: center;
    min-height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    padding: 1rem;
}
.profile {
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
}
.profile-avatar {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.profile-avatar img {
    width: 80%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin-right: auto;
    cursor: pointer;
}
.profile-avatar img:hover {
    filter: brightness(0.9);
}
.profile-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    height: 100%;
}
.profile-details .name {
    font-size: 3rem;
    margin: 0 0 0.2rem 0;
    color: var(--text-color);
}
.profile-details .createdAt {
    margin: 0 0 1.5rem 0;
    font-size: 0.9rem;
    color: var(--small-text-color);
}
.profile-details .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
}
.profile-details .buttons .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--text-color);
    font-size: 1.2rem;
    cursor: pointer;
    background-color: var(--background-color);
    text-decoration: none;
    border: 1px solid var(--accent-color);
}
.profile-details .buttons .button:hover {
    filter: brightness(0.9);
}
.profile-details .buttons .changePassword {
    margin-left: 1rem;
}
.profile-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    height: 100%;
}
.profile-info .infoHeader {
    font-size: 2rem;
    color: var(--text-color);
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--accent-color);
}
.infoForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
}
.infoForm .form-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.infoForm label {
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}
.infoForm input {
    width: calc(100% - 0.5rem - 2px);
    height: 2rem;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    margin-bottom: 1rem;
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding-left: 0.5rem;
}
.infoForm input:focus {
    outline: none;
    border: 1px solid var(--accent-color);
}
.infoForm .button {
    width: 30%;
    height: 3rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    border: none;
    background-color: var(--accent-color);
    color: var(--text-color);
    font-size: 1.2rem;
    cursor: pointer;
}

#modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 45vw;
    height: fit-content;
    overflow: auto;
    border-radius: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 1rem;
    border: 1px solid var(--accent-color);
}
#modal .close {
    position: absolute;
    left: 0.5rem;
    top: 1rem;
    font-size: 1.5rem;
    color: var(--text-color);
    cursor: pointer;
    background-color: transparent;
    border: none;
}
#modal .modal-header {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 2rem 0 0.8rem 0;
}
.modal-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.modal-form input {
    width: 80%;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    margin-bottom: 2rem;
    background-color: var(--secondary-color);
    color: var(--text-color);
}
.modal-form input:focus {
    outline: none;
    border: 1px solid var(--accent-color);
}
.modal-form .button {
    width: 30%;
    height: 3rem;
    border-radius: 5px;
    border: none;
    background-color: var(--accent-color);
    color: var(--text-color);
    font-size: 1.2rem;
    cursor: pointer;
}
#modal-bg, #pfpmodal-bg, #name-modal-bg, #password-modal-bg {
    display: none;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.6;
}

#changePFPModal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    overflow: auto;
    border-radius: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 1rem;
    border: 1px solid var(--accent-color);
}
.small-text {
    margin: 0 0 1rem 0;
    font-size: 0.8rem;
    color: var(--small-text-color);
}
#changePFPModal .close {
    position: absolute;
    left: 0.5rem;
    top: 1rem;
    font-size: 1.5rem;
    color: var(--text-color);
    cursor: pointer;
    background-color: transparent;
    border: none;
}
#changePFPModal .modal-header {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 2rem 0 0.8rem 0;
}

#name-modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 45vw;
    height: fit-content;
    overflow: auto;
    border-radius: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 1rem;
    border: 1px solid var(--accent-color);
}
#name-modal .close {
    position: absolute;
    left: 0.5rem;
    top: 1rem;
    font-size: 1.5rem;
    color: var(--text-color);
    cursor: pointer;
    background-color: transparent;
    border: none;
}
#name-modal .modal-header {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 2rem 0 0.8rem 0;
}

#password-modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 45vw;
    height: fit-content;
    overflow: auto;
    border-radius: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 1rem;
    border: 1px solid var(--accent-color);
}
#password-modal .close {
    position: absolute;
    left: 0.5rem;
    top: 1rem;
    font-size: 1.5rem;
    color: var(--text-color);
    cursor: pointer;
    background-color: transparent;
    border: none;
}
#password-modal .modal-header {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 2rem 0 0.8rem 0;
}

.show {
    display: block !important;
}

@media screen and (max-width: 770px) {
    .Profile main {
        -ms-grid-rows: 2fr 1fr;
        grid-template-rows: 2fr 1fr;
    }
    .profile {
        width: 100%;
        height: fit-content;
        flex-direction: column;
    }
    .profile-avatar {
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .profile-avatar img {
        margin-right: 0;
        margin-bottom: 3rem;
    }
    .profile-details {
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .profile-details .buttons {
        justify-content: center;
        align-items: center;
    }
    .profile-info {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
    }
    .infoForm .button {
        width: 60%;
        margin: 0 auto 1rem auto;
    }
    #modal {
        width: 80vw;
    }
    #changePFPModal {
        width: 80vw;
    }
    #name-modal {
        width: 80vw;
    }
    #password-modal {
        width: 80vw;
    }
}

@media screen and (max-width: 375px) {
    .Profile main {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .profile {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .infoForm input {
        width: calc(100% - 0.5rem - 2px);
    }
    .infoForm .button {
        width: 100%;
    }
}