.ListAppReg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-top: 4rem;
}
.AppRegMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: fit-content;
}
.appRegHeader {
    font-size: 2rem;
    margin: 0;
}
.createAppReg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--accent-color);
    font-size: 1.5rem;
    cursor: pointer;
}
.Reg-Wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    margin-top: 1rem;
}
.AppReg {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% - 2rem);
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
    padding: 1rem;
}
.AppReg .editIcon {
    position: absolute;
    font-size: 1.2rem;
    top: 0.8rem;
    right: 0.8rem;
    color: var(--accent-color);
    cursor: pointer;
}
.AppReg .name {
    font-size: 1.8rem;
    margin: 0 0 0.1rem 0;
}
.AppReg .date {
    font-size: 0.8rem;
    color: var(--small-text-color);
}
.AppReg .url {
    font-size: 1rem;
    color: var(--accent-color);
    text-decoration: none;
}
.AppReg .copyId {
    position: absolute;
    font-size: 1.2rem;
    top: 0.8rem;
    left: 0.5rem;
    color: var(--accent-color);
    cursor: pointer;
    background-color: transparent;
    border: none;
}

@media screen and (max-width: 770px) {
    .Reg-Wrapper {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: repeat(3, 1fr);
    }
    .AppReg .editIcon {
        top: 0.5rem;
        right: 0.4rem;
        font-size: 0.9rem;
    }
    .AppReg .copyId {
        top: 0.5rem;
        left: 0.2rem;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 375px) {
    .Reg-Wrapper {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
}